<template>
  <div>
    <c-card class="cardClassDetailForm" title="LBLDETAIL">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :readonly="true"
            label="심사일"
            name="date"
            v-model="result.date">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :readonly="true"
            label="피심사팀"
            name="deptName"
            v-model="result.deptName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
          <c-text
            :editable="editable"
            :readonly="true"
            label="심사내용"
            name="col1"
            v-model="result.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <c-textarea
            :editable="editable"
            :readonly="true"
            :rows="5"
            label="심사 결과 요약"
            name="auditResult"
            v-model="result.auditResult">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <c-textarea
            :editable="editable"
            :readonly="true"
            :rows="5"
            label="심사 지적사항"
            name="auditErrorList"
            v-model="result.auditErrorList">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <c-textarea
            :editable="editable"
            :readonly="true"
            :rows="5"
            label="기타사항"
            name="etc"
            v-model="result.etc">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      title="시정조치 관리대장 목록"
      :columns="grid.columns"
      :data="result.list"
      :gridHeight="grid.height"
      :editable="editable&&!disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="id"
    >
      <!-- @linkClick="linkClick" -->
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="LBLEXCEPT" icon="remove" @btnClicked="deleteResult" />
          <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addResult" />
          <!-- <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="assessPlan"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveAssessPlan"
            @btnCallback="saveCallback" /> -->
            <c-btn v-if="editable&&!disabled" label="LBLSAVE" icon="save" @btnClicked="saveResult" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <!-- <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
            :key="index"
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="linkClick(item, props.row)">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template>
        <template v-else>
        </template> -->
        <!-- <div v-if="editable && Boolean(props.row.id) && props.row.editFlag !== 'C' && !disabled"> -->
          <q-btn-group outline class="ColumInnerBtnGroup">
            <q-btn
              icon="add"
              color="red-6"
              text-color="white"
              class="ColumInnerBtn"
              align="center"
              @click.prevent="innerBtnClicked(col, props)">
              <q-tooltip>
                개선요청등록
              </q-tooltip>
            </q-btn>
          </q-btn-group>
        <!-- </div> -->
        <c-textarea-column
          :editable="editable"
          :col="col"
          :props="props"
          @datachange="datachange(props, col)"
        />
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    action: {
      type: Object,
      default: () => ({
        id: '',  // 일련번호
        plantCd: '',
        col1: '', // 심사구분
        col2: '', // 심사 준비 요청사항
        col3: '', // 심사 목적 및 범위
        regDtStr: '',
        regUserName: '',
        auditMonthDepts: [], // 심사일정 - 피심사팀에 따른
        auditTeams: [], // 심사팀
        distributeTeams: [], // 배포부서
        checklistResult: [],
      }),
    },
    result: {
      type: Object,
      default: () => ({
        date: '',
        deptName: '',
        auditResult: '',
        auditErrorList: '',
        etc: '',
        col1: '',
        list: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'errorItemName',
            field: 'errorItemName',
            label: '지적항목',
            align: 'left',
            style: 'width:150px',
            sortable: true,
            type: 'custom'
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '개선요청 제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '개선구분',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            label: '요청부서정보',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '500px',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    reg() {
      return this.action.regUserName + ' / ' + this.action.regDtStr
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      let requestContents = props.row.errorItemName;
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: props.row.id,
        ibmTaskTypeCd: 'ITT0000001',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
          if (sData.indexOf(item.sopImprovementId) === -1) {
            this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
              this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
            this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
              this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
            this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
              this.requestImprRow.ibmClassCds + ',' + item.ibmClassCd : item.ibmClassCd
            this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
              this.requestImprRow.ibmTooltip + '│' + item.ibmTooltip : item.ibmTooltip
          }
        })
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    saveResult() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addResult() {
      // window.getApp.$emit('ALERT', {
      //   title: '안내 ', /* 안내 */
      //   message: '준비중.', /* 저장되었습니다. */
      //   type: 'success', // success / info / warning / error
      // });
      if (!this.result.list) this.result.list = [];
      this.result.list.splice(0, 0, {
        id: '',
        errorItemName: '', // 지적항목
        ibmTitle: '', // 개선요청 제목
        ibmClassName: '', // 개선구분
        ibmStepName: '', // 진행상태
        improveRequest: '', // 요청부서정보
        actionDeptName: '', // 조치부서
      })
    },
    deleteResult() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGEXCEPT', // 제외하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>